.App {
  height: 100vh;
  font-family: Noway, sans-serif;
}

div.TombacModal {
  transition: none;
}

div.TombacModal__overlay {
  transition: none;
}
